import styled from 'styled-components/macro';

export const Nav = styled.nav<{ isActive:boolean }>`
    width: 280px;
    height: var(--page-sz-height);
    position: absolute;
    top: var(--header-sz-height);
    right: ${({ isActive }) => isActive ? '0' : `-280px`};
    background: var(--page-bg-primary);
    transition: .5s ease;
    z-index: 4;
    display: flex;
    flex-direction: column;
`;

export const List = styled.ul`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
`;

export const Item = styled.li<{ isActive?:boolean }>`
    height: 35px;
    padding: 0 30px;
    position: relative;
    list-style-type: none;

    :hover {
        background: var(--app-color-hover);
    }

    @media screen and (min-height: 800px) {
        height: 50px;
    }
    
    a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        color: ${({ isActive }) => isActive ? 'var(--brand-color-1)' : 'var(--text-color-primary)'};
    }

    svg {
        width: var(--icon-sz-width);
        height: var(--icon-sz-height);
        margin-right: 20px;

        path {
            fill: ${({ isActive }) => isActive ? 'var(--brand-color-1)' : 'var(--text-color-primary)'};
        }
    }
`;

export const LegalLinkItem = styled(Item)`
    padding: 0 30px 0 0;
    font-size: 14px;
    a {
        color: var(--brand-color-1);
    }
`;

export const ProfileLinkWrapper = styled.div`
    margin-bottom: 10px;
    padding: 20px 30px;
    position: relative;

    :hover { 
        background: var(--app-color-hover);
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
    }

    :after {
        content: '';
        width: 80%;
        height: 1px;
        position: absolute;
        bottom: 0; 
        left: 10%;
        background: var(--app-color-hover);
    }
`;

export const Avatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
`;

export const ProfileData = styled.div`
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    
    h6 { 
        font-size: var(--text-sz-base);
        color: var(--text-color-primary);
    }
    
    p {
        max-width: 130px;
        display: block;
        display: -moz-box;
        display: -webkit-box;
        font-size: 13px;
        color: var(--text-color-secondary);
        word-break: break-all;
        text-overflow: ellipsis;
        line-clamp: 2;
        overflow: hidden;
        -moz-box-orient: vertical;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const Note = styled.span`
    min-width: 20px;
    height: 20px;
    padding: 5px 5px;
    position: absolute;
    right: 20px;
    background: var(--brand-color-1);
    border-radius: 50%;
    font-size: var(--text-sz-note);
    line-height: 10px;
    font-weight: 700;
    text-align: center;
    color: var(--text-color-light);
`;

export const Overflow = styled.div<{ isActive:boolean }>`
    width: 100%;
    height: var(--page-sz-height);
    position: absolute;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    top: var(--header-sz-height);
    right: 0;
    background: var(--app-color-overflow);
    outline: none;
    z-index: 3;
`;

export const LegalLinkWrapper = styled.div`
    margin-top: auto;
    margin-bottom: 100px;
    padding: 10px 30px;
    position: relative;

    @media screen and (min-height: 800px) {
        padding: 20px 30px;
    }
`;
